:root {
  --main-bg-image: url('back.jpg');
  --blend-mode: difference;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes glitch-anim {
  0% {
    transform: translate(var(--translate-x, 0), var(--translate-y, 0)) rotate(-1deg);
    filter: hue-rotate(0deg) contrast(1);
  }

  28% {
    transform: translate(calc(var(--translate-x, 0) + 0.5%), calc(var(--translate-y, 0) - 1%));
    filter: hue-rotate(90deg) contrast(1.1);
  }

  55% {
    transform: translate(calc(var(--translate-x, 0) - 0.2%), calc(var(--translate-y, 0) + 0.5%)) rotate(-.1deg);
    filter: hue-rotate(180deg) contrast(1.2);
  }

  73% {
    transform: translate(calc(var(--translate-x, 0) + 0.5%), calc(var(--translate-y, 0) - 0.5%)) rotate(0.8deg);
    filter: hue-rotate(270deg) contrast(1.3);
  }

  100% {
    transform: translate(calc(var(--translate-x, 0) - 0%), calc(var(--translate-y, 0) + 1%)) rotate(0.3deg);
    filter: hue-rotate(360deg) contrast(1.4);
  }
}

a:link {
  text-decoration: none;
}


a:visited {
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}


a:active {
  text-decoration: none;
}

.main-content-back {
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -8%; // Adjust these values to ensure the images start off centered
    left: -8%; // Adjust these values to ensure the images start off centered
    width: 130%; // Increase the width and height to a larger percentage
    height: 130%; // Increase the width and height to a larger percentage
    background-origin: center;
    background-image: var(--main-bg-image);
    background-size: cover;
    background-position: center center;
    mix-blend-mode: var(--blend-mode);
  }

  &:before {
    animation: glitch-anim 10s infinite ease-in-out alternate-reverse;
  }

  &:after {
    animation: glitch-anim 10s infinite infinite ease alternate;
  }
}

.center-container {
  display: grid;
  /* Use grid instead of flex */
  place-items: center;
  /* Center both horizontally and vertically */
  height: 100vh;
  /* Ensure container takes up whole viewport height */
  overflow-y: auto;
  /* Allow scrolling if content overflows vertically */
  contain: content;
}

@media (max-height: 700px) {
  .center-container {
    align-content: start;
    /* Align to the top if viewport height is less than 700px */
  }
}

.main-content-back:before,
.main-content-back:after,
.sto {
  will-change: transform;
}

.main-artists {
  max-width: 80%;
  font-family: Arial, sans-serif;
  color: #333;
  animation: fadeIn 1s ease-in;
  width: fit-content;
  height: fit-content;
}

@keyframes pulsing {
  0% {
    transform: scale(0.98);
  }

  50% {
    transform: scale(1.0);
  }

  100% {
    transform: scale(0.98);
  }
}

@keyframes hueDistortion {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(180deg);
  }

  100% {
    filter: hue-rotate(0deg);
  }

  /* Reset to original hue at the end of the animation cycle */
}

.head-img {
  background-image: url('bcmc.jpg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  mix-blend-mode: var(--blend-mode);
  min-width: 280px;
  height: 190px;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    animation: pulsing 2s infinite ease-in-out, hueDistortion 5s infinite ease-in-out;
  }
}

.toggle-switch {
  position: fixed;
  bottom: 10px;
  left: 10px;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.toggle-switch label {
  display: flex;
  align-items: center;
}

.toggle-switch input {
  margin-right: 5px;
}

.sto {
  background-image: url('../public/bcmcstore.png');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  mix-blend-mode: var(--blend-mode);
  position: fixed;
  width: 162px;
  height: 170px;
  left: 35px;
  top: 45%;
  transition: transform 0.3s ease;
  z-index: 2;
  opacity: 91%;
  cursor: pointer;

  animation: rotateAnimation 1s linear infinite alternate;
  /* Change the values as needed */
  transform-origin: left bottom;
}

.sto:hover {
  transform: rotate(10deg);
}

@media (max-width: 820px) {
  .sto {
    left: 2px;
  }
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(10deg);
  }
}

.social-icons {
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  z-index: 2;
  mix-blend-mode: var(--blend-mode);

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.1);
    mix-blend-mode: var(--blend-mode);
    transition: transform 0.3s ease-in-out;
    animation: glitch 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;

    &:hover {
      transform: scale(1.1);
    }

    i {
      font-size: 20px;
      color: #ffffff;
      mix-blend-mode: var(--blend-mode);
    }
  }
}

@media (max-width: 768px) {
  .social-icons {
    left: 10px;
    top: 10px;
  }

  .social-icons .icon {
    width: 30px;
    height: 30px;
  }

  .social-icons .icon i {
    font-size: 15px;
  }

  .sto {
    left: 2px;
  }
}

@keyframes glitch {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-1px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-1px);
  }

  100% {
    transform: translateX(1px);
  }
}

.toggle-switch {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
}