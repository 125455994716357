.shooting-stars {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}
  
.star {
    position: absolute;
    width: 2px;
    height: 2px;
    background-color: #ffffff;
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    left: var(--startX);  // Set the initial x-axis position
    bottom: 50%;
    transform-origin: center center;
    animation: shoot linear infinite;
    mix-blend-mode: difference;  // This property will attempt to invert colors
    filter: invert(1);  // This property will invert the color of the star itself
  
    &:nth-child(odd) {
      animation-duration: var(--duration);
    }
  
    &:nth-child(even) {
      animation-duration: var(--duration);
    }
}
  
@keyframes shoot {
    0% {
      transform: translate(0, 0) scale(1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(calc(var(--distance) * cos(var(--angle))), calc(var(--distance) * sin(var(--angle)))) scale(5);
      opacity: 0;
    }
}